<template>
  <div class="control-wrapper has-icon password" :class="{'is-invalid': !valid}">
    <b-form-input
      autocomplete="off"
      :type="!inputStatus ? 'password' : 'text'"
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :state="error ? false : (valid ? true : null)"
      :value="value"
      @input="($event) => $emit('input', $event)"
    ></b-form-input>
    <b-link class="password-handler" :class="{visible: inputStatus, hidden: !inputStatus}" @click="inputStatus = !inputStatus"></b-link>
  </div>
</template>

<style lang="scss" scoped>
@import "./InputPassword";
</style>

<script>
export default {
  props: {
    value: String,
    valid: Boolean,
    error: String,
    disabled: Boolean,
    required: Boolean,
  },

  data() {
    return {
      inputStatus: false,
      placeholder: this.$t('forms.placeholders.password'),
    };
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.placeholder = this.$t('forms.placeholders.password');
      },
    },
  },
};
</script>
